.contact-logo-container {
    display: flex;
    justify-content: left;
    padding-top: 25px;
}

.contact-logo {
    display: flex;
    position: fixed;
    border: 1px solid white;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    top: 4vh;
}

.contact-subtitle {
    width: 100% !important;
}

.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 120px;
}


.socials-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.contact-socials {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.page-footer-fixed {
    margin-top: auto;
}

.footer {
    margin-top: 0;
}