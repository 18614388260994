@import "../../../data/styles.css";

.project {
    mix-blend-mode: normal;
    border-radius: 20px;
    opacity: 0.8;
    height: 100%;
}

.project a {
    text-decoration: none;
}

.project:hover {
    background: #fafafa;
    opacity: 1;
    transition: background-color 0.3s ease-in-out;
}

.project:hover .project-link {
    color: var(--link-color);
    transition: color 0.3s ease-in-out;
}

.project-container {
    padding: 30px;
}

.project-logo {
    width: 30px;
    display: flex;
    align-items: center;
}

.techno-name {
    font-size: 12px;
    color: var(--primary-color);
    padding-left: 7px;
}

.project-title {
    font-family: var(--secondary-font), serif;
    color: var(--primary-color);
    font-size: 16px;
    padding-top: 15px;
    margin-bottom: 1em;
    font-weight: 700;
}

.project-description {
    color: var(--secondary-color);
}

.project-link {
    display: flex;
    align-items: center;
    color: var(--secondary-color);
    font-size: 12px;
    padding-top: 20px;
}

.project-link-icon {
    padding-left: 5px;
    font-size: 13px;
}

.project-link-text {
    padding-left: 20px;
    font-weight: 700;
}

@media (max-width: 600px) {
    .project {
        height: 300px;
    }
}
